import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ApedaLogo from "./apeda_logo.png"
import service from './Service/service';
// import css from "./App.css"

const Visitors = () => {
    const [inputVisitors, setInputVisitors] = useState({
        field1: "",
        field2: "",
        field3: "",
        field4: "",
        field5: "",
        field6: "",
        field7: "",
    });
    const [enableSpecify1, setEnableSpecify1] = useState(false);
    const [enableSpecify2, setEnableSpecify2] = useState(false);
    const [apiResponse, setApiResponse] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [downloadMessage, setDownloadMessage] = useState("");

    useEffect(() => {
    }, [])


    const inputVisitorsHandler = (e) => {
        const { name, value } = e.target;
        setInputVisitors((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onButtonClick = () => {
        const pdfUrl = "/Apeda.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Apeda_Participants.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadMessage("Download started...");
        setTimeout(() => setDownloadMessage(""), 3000);
    };


    const save = async (evt) => {

        evt.preventDefault();
        setLoading(true);
        var data = {
            ...inputVisitors,
        }
        console.log(data);
        debugger
        if (data) {

            service.createorupdate("Apeda/CreateOrUpdate", data)
                .then(res => {
                    setApiResponse(res.data);
                    setLoading(false);
                    setShowModal(true);
                })
                .catch(error => {
                    console.error("API Error:", error);
                });
        }
    }


    return (
        <div className="container-fluid p-3">

            <div className="row justify-content-center">
                <div className="col-md-10 justify-content-center p-1 d-flex" style={{ border: "5px solid black" }}>

                    <div className="col-md-12" style={{ border: "3px solid black" }}>
                        <div className="col-sm-12 d-flex flex-wrap justify-content-sm-between justify-content-center mt-4 mb-4">
                            <div className="col-md-3 col-lg-2 col-7 col-sm-4 mb-2">
                                <img className='img-fluid' src={ApedaLogo} alt="" />
                            </div>
                            <div className="col-md-5 col-sm-5 col-12 col-lg-3  d-flex text-center align-self-center">
                                <p className='h5'>INDUS FOOD 8-Jan-2025 to 10-Jan-2025 GREATER NOIDA</p>
                            </div>
                            <div className="col-md-4  d-flex align-items-center justify-content-md-end justify-content-center">
                                <Link to="/" className="btn btn-info">Back</Link>
                            </div>
                        </div>

                        <div className="col-sm-12 bg-success rounded text-light">
                            <p className='h4 text-center py-1'>Feedback Form Visitors</p>
                        </div>
                        <p className='text-secondary mt-2 px-3'>Thank you for taking the time to complete this survey.</p>
                        <p className='text-secondary px-3'>Your feedback is important and will help us create a more successful Pavilion experience.</p>
                        <div className="col-sm-12 mt-3">
                            <form onSubmit={(e) => save(e)} className='form'>
                                <div className="mb-3">
                                    <label className="form-label"><b>1-</b> How did you hear about the APEDA Pavilion at Indusfood 2025?</label>
                                    <select className='form-control' onChange={inputVisitorsHandler} name="field1" id="">
                                        <option value="">NONE</option>
                                        <option value="Social media">Social media</option>
                                        <option value="Internet search">Internet search</option>
                                        <option value="Word of mouth">Word of mouth</option>
                                        <option value="Advertisement">Advertisement</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label"><b>2-</b> How likely are you to recommend APEDA Pavilion to a friend/colleague?</label>
                                    <select className='form-control' onChange={inputVisitorsHandler} name="field2" id="">
                                        <option value="">NONE</option>
                                        <option value="Extremely Likely">Extremely Likely</option>
                                        <option value="Likely">Likely</option>
                                        <option value="Neutral">Neutral</option>
                                        <option value="Unlikely">Unlikely</option>
                                        <option value="Extremely unlikely">Extremely unlikely</option>
                                    </select>
                                </div>
                                <div className="">
                                    <label className="form-label"><b>3-</b> On a scale of 1-10, how satisfied were you with the visit to APEDA Pavilion?</label>
                                    <input className='ml-1' type='number' onChange={inputVisitorsHandler} min={1} max={10} name="field3" id="">

                                    </input>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">
                                        <b>4-</b> What was the most valuable aspect of APEDA Pavilion experience?
                                    </label>
                                    <select
                                        className="form-control"
                                        name="field4"
                                        id="exampleInputEmail1"
                                        onChange={(e) => {
                                            inputVisitorsHandler(e);
                                            setEnableSpecify1(e.target.value === "Other");
                                        }}
                                    >
                                        <option value="">NONE</option>
                                        <option value="Location">Location</option>
                                        <option value="Networking opportunities">Networking opportunities</option>
                                        <option value="Product presentations">Product presentations</option>
                                        <option value="Exhibitor displays">Exhibitor displays</option>
                                        <option value="Other">Other (please specify)</option>
                                    </select>
                                    {enableSpecify1 && (
                                        <input
                                            type="text"
                                            onChange={inputVisitorsHandler}
                                            name="field4"
                                            className="form-control mt-2"
                                            placeholder="Please specify"
                                        />
                                    )}
                                </div>

                                <div className="mb-3">
                                    <label className="form-label"><b>5-</b> Were you able to find the products, which you were looking for?</label>
                                    <select className='form-control' name="field5" id="" onChange={(e) => {
                                        inputVisitorsHandler(e);
                                        setEnableSpecify2(e.target.value === "NO")
                                    }}>
                                        <option value="">NONE</option>
                                        <option value="YES">YES</option>
                                        <option value="NO">NO (please specify)</option>
                                    </select>
                                    {enableSpecify2 && <input type="text" name='filed5' onChange={inputVisitorsHandler}
                                        className="form-control mt-2" placeholder='Please specify' />}
                                </div>

                                <div className="mb-3">
                                    <label className="form-label"><b>6-</b> What was the most interesting about APEDA Pavilion?</label>
                                    <input type="text" className="form-control" onChange={inputVisitorsHandler} name='field6' placeholder='interesting experience' />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label"><b>7-</b> Provide Suggestions if you have any.</label>
                                    <input type="text" className="form-control" onChange={inputVisitorsHandler} name='field7' placeholder='suggestions if any?' />
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-11 mt-3 d-flex justify-content-end'>
                                            {loading ? <div>Saving...</div>
                                                : <button type='submit' className="btn btn-success">Submit</button>}
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <div className={`modal fade ${showModal ? "show" : ""}`} tabIndex="-1" style={{ display: showModal ? "block" : "none", height: "100vh" }} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{ height: "90vh" }}>
                        <div className="modal-header">
                            <h5 className="modal-title">Thank You for Your Feedback!</h5>
                            <button type="button" className="btn-close" onClick={() => setShowModal(false)}>❌</button>
                        </div>
                        <div className="modal-body d-flex flex-column align-items-center justify-content-center">
                            <div className="col-sm-4">
                                <img className='img-fluid' src={ApedaLogo} alt="" />
                            </div>
                            <p>Apeda appreciate your valuable input.</p>
                            <button className='btn btn-sm btn-success' onClick={onButtonClick}>
                                Download Participants PDF
                            </button>
                            {downloadMessage && (
                                <div className="alert alert-info mt-3" role="alert">
                                    {downloadMessage}
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Visitors
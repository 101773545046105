import React, { useEffect, useState } from 'react'
import "./App.css"
import service from './Service/service';

const ExhibitorsList = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        getData("ApedaExhibitor/GetAll");
        setInterval(() => {
            getData("ApedaExhibitor/GetAll");
        }, 10000);
    }, [])

    const getData = (method) => {
        service.getdata(method).then((res) => {
            setData(res.data);
            console.log(res.data);
            debugger
        });
    }

    function dateFormat(dateString) {
        const date = new Date(dateString);

        // Define options for human-readable format
        const options = {
            weekday: "long", // "Monday"
            year: "numeric", // "2025"
            month: "long",   // "January"
            day: "numeric",  // "8"
            hour: "2-digit", // "03"
            minute: "2-digit", // "51"
            // second: "2-digit", // "52"
            hour12: true,    // "AM/PM format"
        };

        // Format the date
        return date.toLocaleString("en-US", options);
    }



    return (
        <div className='py-4'>
            <h3>Visitor's Feedback</h3>
            <table id="customers">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Company Name</th>
                    <th scope="col">Contact Person</th>
                    {/* <th scope="col">Address</th> */}
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Website</th>
                    <th scope="col">Enquiries</th>
                    <th scope="col">Business Worth</th>
                    <th scope="col">Product Displayed</th>
                    <th scope="col">Product Response</th>
                    <th scope="col">MACFRUT Experience</th>
                    <th scope="col"> Pavilion Rating</th>
                    <th scope="col">Management Rating</th>
                    <th scope="col">Suggestions</th>
                    <th scope="col">INDUS FOODS 2026 Participation</th>
                    <th scope="col">Created At</th>
                </tr>
                {data.map((res) => {
                    return (<tr>
                        <td>{res.id}</td>
                        <td>{res.companyName}</td>
                        <td>{res.contactName}</td>
                        {/* <td>{res.address}</td> */}
                        <td>{res.contactEmail}</td>
                        <td>{res.contactPhone}</td>
                        <td>{res.website}</td>
                        <td>{res.enquiries}</td>
                        <td>{res.businessWorth}</td>
                        <td>{res.productDisplayed}</td>
                        <td>{res.productResponse}</td>
                        <td>{res.overallExperience}</td>
                        <td>{res.overallManagement}</td>
                        <td>{res.designRating}</td>
                        <td>{res.participation}</td>
                        <td>{res.suggestion}</td>
                        <td>{dateFormat(res.createdAt)}</td>
                    </tr>)
                })}

            </table>
        </div>
    )
}

export default ExhibitorsList
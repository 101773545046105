import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ApedaLogo from "./APEDA-Logo.png"
import logo from "./logo.png"
import css from "./App.css"
import axios from 'axios';
import service from './Service/service';


const Exhibitors = () => {
    const [inputExhibitors, setExhibitors] = useState({
        companyName: "",
        contactName: "",
        address: "",
        contactEmail: "",
        contactPhone: "",
        website: "",
        enquiries: "",
        businessWorth: "",
        productDisplayed: "",
        productResponse: "",
        overallExperience: "",
        overallManagement: "",
        designRating: "",
        participation: "",
        suggestion: "",
    });
    const [apiResponse, setApiResponse] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const inputBox = {

        outline: "0",
        borderWidth: "0 0 2px",
        borderColor: "green",
    }

    // css end

    const inputVisitorsHandler = (e) => {
        const { name, value } = e.target;
        setExhibitors((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const save = (evt) => {
        evt.preventDefault();
        setLoading(true)

        var exhibitorsData = {
            ...inputExhibitors,
        }
        console.log(exhibitorsData);
        debugger
        if (exhibitorsData) {

            service.createorupdate("ApedaExhibitor/CreateOrUpdate", exhibitorsData)
                .then(res => {
                    setApiResponse(res.data);
                    setLoading(false);
                    setInterval(() => {
                        setApiResponse(false);
                        setLoading(false);

                    }, 5000)
                })
                .catch(error => {
                    console.error("API Error:", error);
                });
        }

    }

    return (
        <div className="container-fluid p-3">

            <div className="row justify-content-center p-3">
                <div className="col-sm-10 justify-content-center p-1 d-flex" style={{ border: "5px solid black" }}>

                    <div className="col-md-12" style={{ border: "3px solid black" }}>
                        <div className="col-md-12  d-flex flex-wrap justify-content-between mb-4">

                            <div className="col-md-8 mt-3 d-flex justify-content-center justify-content-md-start ">

                                <img src={ApedaLogo} className='img-fluid' style={{}} alt="" />
                            </div>

                            <div className="col-md-4  d-flex align-items-center justify-content-md-end justify-content-center">
                                <Link to="/" className="btn btn-info">Back</Link>
                            </div>

                        </div>
                        <div className="col-sm-12 d-flex justify-content-center">
                            <div className="col-md-4 text-center flex-column">
                                <h5>INDUS FOOD 2025</h5>
                                <h5>8<sup>th</sup> to 10<sup>th</sup> JAN 2025</h5>
                                <h5>GREATER NOIDA</h5>
                            </div>
                        </div>
                        <div className="col-sm-12  mt-3 p-1 text-center bg-success text-light rounded">
                            <h4>Feedback Form Exhibitor</h4>
                        </div>
                        <div className="col-sm-12 mt-3 ">
                            <form onSubmit={(e) => save(e)} className='form p-2'>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Name Of Company </b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='companyName' className='form-control mt-3 mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 required'><b>Contact Person's Name</b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='contactName' className='form-control mt-3 mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Address</b></div>
                                        <div className='col-sm-10'>
                                            <textarea cols={20} rows={1} type="text" name='address' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 required'><b>Email</b></div>
                                        <div className='col-sm-10'>
                                            <input type="email" name='contactEmail' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Contact Number</b></div>
                                        <div className='col-sm-10'>
                                            <input type="number" name='contactPhone' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Website</b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='website' className='form-control mt-2 mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>No. Business Enquiries</b></div>
                                        <div className='col-sm-10'>
                                            <input type="number" name='enquiries' className='form-control mt-3 mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Business Worth</b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='businessWorth' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 ">
                                    <div className="form-group row">
                                        <div className='col-lg-3 col-sm-5 '><b>Product Displayed</b></div>
                                        <div className='col-sm-2 d-flex'>
                                            <div>Yes</div>
                                            <input type="radio" name="productDisplayed" value={true} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-sm-2 d-flex'>
                                            <div>No&nbsp;</div>
                                            <input type="radio" name="productDisplayed" value={false} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>



                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-lg-7 col-sm-11 '><b>How was the response of your products in this event? </b></div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>1</div>
                                            <input type="radio" name="productResponse" value={1} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>2</div>
                                            <input type="radio" name="productResponse" value={2} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>3</div>
                                            <input type="radio" name="productResponse" value={3} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>4</div>
                                            <input type="radio" name="productResponse" value={4} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>5</div>
                                            <input type="radio" name="productResponse" value={5} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>

                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-lg-7 col-sm-11 '><b>How was your overall experience at Indus Food 2025?  </b></div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>1</div>
                                            <input type="radio" name="overallExperience" value={1} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>2</div>
                                            <input type="radio" name="overallExperience" value={2} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>3</div>
                                            <input type="radio" name="overallExperience" value={3} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>4</div>
                                            <input type="radio" name="overallExperience" value={4} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>5</div>
                                            <input type="radio" name="overallExperience" value={5} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>

                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-lg-7 col-sm-11  '><b>How did the design and layout of the APEDA Pavilion?  </b></div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>1</div>
                                            <input type="radio" name="designRating" value={1} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>2</div>
                                            <input type="radio" name="designRating" value={2} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>3</div>
                                            <input type="radio" name="designRating" value={3} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>4</div>
                                            <input type="radio" name="designRating" value={4} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>5</div>
                                            <input type="radio" name="designRating" value={5} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>

                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-lg-7 col-sm-11 '><b>How did you find the overall management of the Pavilion?  </b></div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>1</div>
                                            <input type="radio" name="overallManagement" value={1} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>2</div>
                                            <input type="radio" name="overallManagement" value={2} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>3</div>
                                            <input type="radio" name="overallManagement" value={3} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>4</div>
                                            <input type="radio" name="overallManagement" value={4} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>5</div>
                                            <input type="radio" name="overallManagement" value={5} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>

                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Suggetions Comment</b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='suggestion' className='form-control mb-1 mt-4 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-lg-7 col-sm-11'><b>Would you like to participate at INDUS FOOD 2026:</b></div>
                                        <div className='col-lg-2 d-flex'>
                                            <div>Yes</div>
                                            <input type="radio" name="participation" value={true} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-2 d-flex'>
                                            <div>No&nbsp;</div>
                                            <input type="radio" name="participation" value={false} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>

                                {apiResponse &&
                                    <div className="alert alert-info text-end mt-3" role="alert">
                                        Response saved successfully...!!
                                    </div>}
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-11 mt-3 d-flex justify-content-end'>
                                            {loading ? <div>Saving...</div>
                                                : <button type='submit' className="btn btn-success">Submit</button>}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>

    )
}

export default Exhibitors
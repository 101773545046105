import axios from "axios";

//const EMPLOYEE_API_BASE_URL = "https://localhost:7116/api/";

const EMPLOYEE_API_BASE_URL = "https://hybrid-exhibit.com/api/";

class CustomerService {
    async getdata(method) {
        try {
            const res = await axios
                .get(EMPLOYEE_API_BASE_URL + method)
                .then((response) => {
                    console.log(response);
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
        }
    }

    async createorupdate(method, value) {
        try {
            const res = await axios
                .post(EMPLOYEE_API_BASE_URL + method, value)
                .then((response) => {
                    console.log(response);
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
        }
    }


}

export default new CustomerService();

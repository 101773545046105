import { Link } from 'react-router-dom';
import logo from "./logo.png"
import ApedaLogo from "./APEDA-Logo.png"
import Apedalogo from "./apeda_logo.png"
import indiaOrganic from "./indiaOrganiclogo.png"
import './App.css';
import { useState } from 'react';

const logo1 = {
  maxWidth: "58%",
  height: "60%"
}

const logo2 = {
  maxWidth: "110%",
  height: "50%"
}

const logo3 = {
  maxWidth: "35%",
  height: "50%"
}

function Home() {
  const [showModal, setShowModal] = useState(false);
  const [downloadMessage, setDownloadMessage] = useState("");

  const onButtonClick = () => {
    const pdfUrl = "/Apeda.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Apeda_Participants.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloadMessage("Download started...");
    setShowModal(true);
    setTimeout(() => {
      setDownloadMessage("");
      setShowModal(false);
    }, 3000);
  };

  return (
    <div className="container-fluid" style={{ minHeight: "100vh" }}>
      <div className="row align-items-center  rounded justify-content-between  p-3" style={{ border: "3px solid green" }}>
        <div className="col-lg-4 mt-2 d-flex justify-content-center justify-content-lg-start" >
          {/* <img src={logo} style={logo1} alt="" /> */}
        </div>

        <div className="col-lg-4 mt-4 d-flex justify-content-center" >
          <img src={ApedaLogo} style={logo2} alt="" />
        </div>

        <div className="col-lg-4 mt-3 d-flex  justify-content-lg-end justify-content-center" >
          {/* <img src={indiaOrganic} style={logo3} alt="" /> */}
        </div>

      </div>
      <div className="row justify-content-center align-items-center flex-column p-5  mb-2 mt-5">
        <div className="col-md-6 col-12 mt-2 d-flex justify-content-center mb-2" >
          <Link to="/Visitors" type="button" className="btn exhibitor btn-success btn-lg btn-block" style={{ fontSize: "17px" }}>Visitor Feedback Form</Link>
        </div>
        <div className="col-md-6 col-12 mt-2 d-flex justify-content-center mb-2">
          <button onClick={onButtonClick} className="btn exhibitor btn-success btn-lg btn-block" style={{ fontSize: "17px" }}>
            Download Participants PDF
          </button>
        </div>
        <div className="col-md-6 col-12 d-flex justify-content-center mt-2 mb-2">
          <Link to="/Exhibitors" className="btn exhibitor btn-success btn-lg btn-block" style={{ fontSize: "15px" }}>Exhibitors Feedback Form</Link>
        </div>
      </div>
      <div>

      </div>
      {/* Modal */}
      <div className={`modal fade ${showModal ? "show" : ""}`} tabIndex="-1" style={{ display: showModal ? "block" : "none" }} role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{}}>
            <div className="modal-header">
              <h5 className="modal-title">Thank You Downloading!</h5>
              <button type="button" className="btn-close" onClick={() => setShowModal(false)}>❌</button>
            </div>
            <div className="modal-body d-flex flex-column align-items-center justify-content-center">
              <div className="col-sm-4">
                <img className='img-fluid' src={Apedalogo} alt="" />
              </div>
              <p>Apeda appreciate your valuable input.</p>

              {downloadMessage && (
                <div className="alert alert-info mt-3" role="alert">
                  {downloadMessage}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

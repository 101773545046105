import React, { useEffect, useState } from 'react'
import "./App.css"
import service from './Service/service';

const VisitorsList = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData("Apeda/GetAll");
    setInterval(() => {
      getData("Apeda/GetAll");
    }, 10000);
  }, [])

  const getData = (method) => {
    service.getdata(method).then((res) => {
      setData(res.data);
      console.log(res.data);
      debugger
    });
  }
  return (
    <div className='py-4'>
      <h3>Visitor's Feedback</h3>
      <table id="customers">
        <tr>
          <th>SNo.</th>
          <th>Question1</th>
          <th>Question2</th>
          <th>Satisfaction Rating (1-10)</th>
          <th>Pavillion Experience</th>
          <th>Question5</th>
          <th>Interesting Experience</th>
          <th>Suggestion</th>
          <th>Created Date</th>
        </tr>
        {data.map((res) => {
          return (<tr>
            <td>{res.id}</td>
            <td>{res.field1}</td>
            <td>{res.field2}</td>
            <td>{res.field3}</td>
            <td>{res.field4}</td>
            <td>{res.field5}</td>
            <td>{res.field6}</td>
            <td>{res.createdAt}</td>
          </tr>)
        })}

      </table>
    </div>
  )
}

export default VisitorsList